import React, { useState, useRef, useEffect } from 'react';

export const NotFound = () => {
  useEffect(() => {
    const updatePageTitle = () => {
        document.title = "Cricket8";
    };
    updatePageTitle();
    return () => {
    };
  }, []);
  return (
    <div id="content" className="min-h-[75vh] mt-[0px] md:mt-[20px]">
        <h2 className="text-[14px] md:text-[20px]">Cricket8</h2>
        <h1 className="text-[30px] md:text-[55px]">404 not found</h1>

        <div className='font-chakra font-[#fff] text-[16px] mt-[30px]'>
            <p className='pb-[20px]'>
                Sorry, the page you are looking for does not exist.
            </p>
            <p className='pb-[20px]'>
                Back to the <a href='/' className='text-white underline'>home page</a>.
            </p>
        </div>

    </div>
  );
};
export default NotFound;
